import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { useSelector } from 'react-redux'
import { isInStandaloneMode, isIOS, isMacOS } from '../utils/helpers'

import { ReactComponent as AppleShareIcon } from '../assets/icons/share-apple.svg'
import { RootState } from '../redux'

const AddToHomeScreenBanner: React.FC = () => {
	const { t } = useTranslation()
	const isLoggedIn = useSelector((state: RootState) => !!state.userAuth.user)

	const [prompt, setPrompt] = useState<Event | null>(null)
	const [isIOSPrompt, setIsIOSPrompt] = useState(false)
	const [isMacOSPrompt, setIsMacOSPrompt] = useState(false)

	const getIsDismissed = useCallback(() => sessionStorage.getItem('home_banner_dismissed'), [])

	useEffect(() => {
		if (!isLoggedIn && (isIOS() || isMacOS()) && !isInStandaloneMode() && !getIsDismissed()) {
			setIsIOSPrompt(isIOS())
			setIsMacOSPrompt(isMacOS())
		}
	}, [getIsDismissed, isLoggedIn])

	useEffect(() => {
		const handler = (event: Event) => {
			event.preventDefault()
			if (getIsDismissed()) return
			setPrompt(event)
		}
		window.addEventListener('beforeinstallprompt', handler)
		return () => {
			window.removeEventListener('beforeinstallprompt', handler)
		}
	}, [getIsDismissed, isLoggedIn])

	const dismiss = (setDismissed: boolean) => {
		if (prompt) setPrompt(null)
		if (isIOSPrompt) setIsIOSPrompt(false)
		if (isMacOSPrompt) setIsMacOSPrompt(false)
		if (setDismissed) sessionStorage.setItem('home_banner_dismissed', 'True')
	}

	const handleInstallClick = async () => {
		try {
			if (!prompt) return
			;(prompt as any).prompt()
			const choiceResult = await (prompt as any).userChoice
			if (choiceResult.outcome === 'accepted') {
				dismiss(false)
			}
		} catch (installerror) {
			Sentry.captureException(installerror)
			console.error('Installation failed', installerror)
		}
	}

	if (isLoggedIn) {
		return null
	}

	if (isIOSPrompt) {
		return (
			<div
				className={'flex items-center fixed left-1/2 transform -translate-x-1/2 bg-purple-light shadow-lg p-4 rounded-md'}
				style={{
					backgroundColor: '#F1EDFF',
					padding: '0.5rem',
					margin: '1rem',
					borderRadius: '0.625rem',
					boxShadow: '0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.1)',
					// maxWidth: '26rem',
					zIndex: 2,
					bottom: '3rem'
				}}
			>
				<div>
					<p>
						{t("loc:general|Download the WageNow app to your Home Screen. It's easy, press share")}
						<span
							style={{
								cursor: 'text',
								display: 'inline-flex',
								verticalAlign: 'middle',
								padding: '0 0.2rem'
							}}
						>
							<AppleShareIcon />
						</span>
						{t('loc:general|and then')} &quot;{t('loc:general|Add to home screen')}&quot;.
					</p>
				</div>
				<CloseOutlined onClick={() => dismiss(true)} className={'ml-4'} />
				<div
					className='speech-bubble-arrow'
					style={{
						position: 'absolute',
						bottom: '-2rem', // Adjust this to shift arrow position up or down
						left: '50%',
						transform: 'translateX(-50%)',
						width: '0',
						height: '0',
						borderLeft: '1rem solid transparent',
						borderRight: '1rem solid transparent',
						borderTop: '2rem solid #F1EDFF', // Adjust this to change arrow length and color
						borderRadius: '0 0 1rem 1rem'
					}}
				/>
			</div>
		)
	}

	if (isMacOSPrompt) {
		return (
			<div className={'flex items-center absolute right-4 bottom-10 bg-purple-light shadow-lg p-4 rounded-md max-w-1/5 mr-4'} style={{ zIndex: 2 }}>
				<div>
					<p>{t('loc:general|Add to home screen on macOS')}:</p>
					<ul className={'list-disc list-inside'}>
						<li>{t('loc:general|Click the "Share" button in the toolbar')}</li>
						<li>{t('loc:general|Select "Add to dock"')}</li>
						<li>{t('loc:general|Click "Add"')}</li>
					</ul>
				</div>
				<CloseOutlined onClick={() => dismiss(true)} className={'ml-4'} />
			</div>
		)
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{!!prompt && (
				<div
					className={'flex items-center absolute right-4 bottom-10 bg-purple-light shadow-lg p-4 rounded-md max-w-1/5 mr-4 ml-4'}
					style={{ zIndex: 2 }}
				>
					<p>{t('loc:general|Add to home screen')}</p>
					<Button type={'primary'} shape={'round'} onClick={handleInstallClick} className='text-16 max-w-32 max-h-10 ml-4'>
						{t('loc:general|Install')}
					</Button>
					<CloseOutlined onClick={() => dismiss(true)} className={'ml-4'} />
				</div>
			)}
		</>
	)
}

export default AddToHomeScreenBanner
