import { Spin } from 'antd'
import { FC, Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import rootReducer from './redux'
import configureStore from './redux/configureStore'
import Routes from './routes/Routes'
import i18n from './utils/i18n'
import OfflinePage from './pages/OfflinePage'

const { store, persistor } = configureStore(rootReducer)

const App: FC = () => {
	return (
		<Suspense fallback={<Spin size={'large'} />}>
			<I18nextProvider i18n={i18n}>
				<PersistGate loading={<Spin size={'large'} />} persistor={persistor}>
					<Provider store={store}>
						<OfflinePage>
							<Routes />
						</OfflinePage>
					</Provider>
				</PersistGate>
			</I18nextProvider>
		</Suspense>
	)
}

export default App
